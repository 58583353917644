<template>
    <div>
        <b-card title="Edit User">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Username" rules="required">
                                <h6 class="">Username</h6>
                                <b-form-input type="text" v-model="body.User_name" :state="errors.length > 0 ? false : null"
                                    :disable="disable" autofocus />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Comments" rules="">
                                <h6 class="">Comments</h6>
                                <b-form-input type="text" v-model="body.Comments" :state="errors.length > 0 ? false : null"
                                    :disable="disable" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row v-if="body.active_directory === 'false'">
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Password" rules="required">
                                <h6 class="mt-2">New Password</h6>
                                <b-form-input type="password" v-model="body.User_pswd"
                                    :state="errors.length > 0 ? false : null" :disable="disable" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Repeat Password"
                                rules="required|confirmed:Password">
                                <h6 class="mt-2">Repeat New Password</h6>
                                <b-form-input type="password" v-model="repeatPassword"
                                    :state="errors.length > 0 ? false : null" :disable="disable" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="ID User Group" rules="required">
                                <h6 class="mt-2">ID User Group</h6>
                                <v-select multiple :options="userGroups" v-model="userGroupSelected"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Active" rules="">
                                <h6 class="mt-2">Active</h6>
                                <b-form-select v-model="body.Active" :state="errors.length > 0 ? false : null"
                                    :options="activeOptions" :disable="disable" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Sec Session" rules="">
                                <h6 class="mt-2">Sec Session</h6>
                                <b-form-input type="number" v-model="body.Sec_session"
                                    :state="errors.length > 0 ? false : null" :disable="disable" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Active Directory" rules="required">
                                <h6 class="mt-2">Active Directory</h6>
                                <b-form-select v-model="body.active_directory" @change="configureActiveDirectory()"
                                    :state="errors.length > 0 ? false : null" :options="activeOptions" :disable="disable" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Aforo Main" rules="required">
                                <h6 class="mt-2">Aforo Main</h6>
                                <b-form-select v-model="aforoMainSelected" :options="aforos" @change="configureAforoMain()"
                                    :disable="disable" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Aforo Permit" rules="required">
                                <h6 class="mt-2">Aforo Permit</h6>
                                <v-select multiple :options="aforos" v-model="aforoPermitSelected"></v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <!-- si ad true amagar el passw -->
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-row class="mt-2">
                <b-col></b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                    :hidden="hidden">
                    <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Update User
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/users">
                    <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Go Back
                </b-button>
                <b-col></b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BFormSelect } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import vSelect from 'vue-select'
import { decryptString } from '@/libs/crypto'

export default {
    components: {
        BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BFormSelect, ValidationProvider, ValidationObserver,
        vSelect
    },
    watch: {
        aforoPermitSelected(value) {
            this.body.Aforo_permit = "";
            let first = true;
            value.forEach(element => {
                const idAforo = element.split(" - ")[1].replace(/[()]/g, "");
                if (first) {
                    this.body.Aforo_permit = idAforo;
                    first = false;
                } else {
                    this.body.Aforo_permit += "," + idAforo;
                }
            })
        },
        userGroupSelected(value) {
            this.body.ID_user_group = "";
            let first = true;
            value.forEach(element => {
                const idUserGroup = element.split(" - ")[1].replace(/[()]/g, "");
                if (first) {
                    this.body.ID_user_group = idUserGroup;
                    first = false;
                } else {
                    this.body.ID_user_group += "," + idUserGroup;
                }
            })
        },
    },
    methods: {
        configureUserGroup() {
            const userGroup = this.userGroupSelected.split(" - ");
            const idUserGroup = userGroup[1].replace(/[()]/g, "")
            this.body.ID_user_group = idUserGroup;
        },
        configureActiveDirectory() {
            if (this.body.active_directory === 'false') {
                this.body.User_pswd = ''
            }
        },
        configureAforoMain() {
            const aforo = this.aforoMainSelected.split(" - ");
            const idAforo = aforo[1].replace(/[()]/g, "")
            this.body.Aforo_main = idAforo;
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.message();
                }
            })
        },
        async message() {
            const error = await this.onSubmit();
            if (error[0]) {
                const variant = "danger";
                this.$bvToast.toast("" + error[1], {
                    title: "Failed",
                    variant,
                    solid: true,
                });
            } else if (!error[0]) {
                this.disable = true;
                this.hidden = true;
                const variant = "success";
                this.$bvToast.toast("User updated correctly", {
                    title: `Update`,
                    variant,
                    solid: true,
                });
            }
        },
    },
    data() {
        return {
            required,
            disable: false,
            hidden: false,
            aforos: [],
            aforoSelected: "",
            aforoPermitSelected: [],
            aforoMainSelected: "",
            doors: [],
            selected: [],
            activeOptions: [
                { value: "true", text: 'Active' },
                { value: "false", text: 'Inactive' },
            ],
            userGroupSelected: [],
            userGroups: [],
        };
    },
    async created() {
        await axios
            .get("/user?ID_user=" + this.$route.params.id)
            .then((result) => {
                this.body = result.data[0];
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
            await axios
            .get("/user-group")
            .then((results) => {
                results.data.forEach(element => {
                    this.userGroups.push(element.Description + " - (" + element.ID_user_group + ")")
                    if (element.ID_user_group.toString() === this.body.ID_user_group) {
                        this.userGroupSelected.push(element.Description + " - (" + element.ID_user_group + ")")
                    }
                });
                if (this.body.ID_user_group) {
                    let userGroups = [];
                    if (this.body.ID_user_group.includes(',')) {
                        console.log(this.userGroupSelected);

                        userGroups = this.body.ID_user_group.split(",");
                        userGroups.forEach(userGroup => {
                        this.userGroups.forEach(group => {
                            const userGroupSplitted = group.split(" - ")
                            const idUserGroup = userGroupSplitted[1].replace(/[()]/g, "")
                            if (userGroup === idUserGroup) {
                                this.userGroupSelected.push(group)
                            }
                        })
                    })
                    } else {
                        userGroups.push(this.body.ID_user_group);
                        console.log(this.userGroupSelected);
                    }
                };
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
        await axios
            .get("/aforo")
            .then((results) => {
                results.data.forEach(element => {
                    this.aforos.push(element.Aforo_name + " - (" + element.ID_Aforo + ")")
                    if (element.ID_Aforo === this.body.Aforo_main) {
                        this.aforoMainSelected = element.Aforo_name + " - (" + element.ID_Aforo + ")"
                    }
                });
                if (this.body.Aforo_permit) {
                    let aforosAllowed = this.body.Aforo_permit.split(",");
                    aforosAllowed.forEach(aforoPermit => {
                        this.aforos.forEach(aforo => {
                            const aforoSplitted = aforo.split(" - ")
                            const idAforo = aforoSplitted[1].replace(/[()]/g, "")
                            if (aforoPermit === idAforo) {
                                this.aforoPermitSelected.push(aforo)
                            }
                        })
                    })
                };
                // this.aforos.forEach(element)
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
    },
    mounted() {
        if (localStorage.getItem("accessToken") === null) {
            this.$router.go(0)
        }
    },
    setup() {
        const blankUserData = {
            ID_user: "",
            User_name: "",
            User_pswd: "",
            Comments: "",
            ID_user_group: "",
            Active: "",
            Sec_session: "",
            active_directory: "",
            Aforo_main: "",
            Aforo_permit: "",
            IdUser: decryptString(localStorage.getItem('idUser')),
        };

        const body = ref(JSON.parse(JSON.stringify(blankUserData)));

        const onSubmit = async () => {
            const onError = [false, ""];
            await axios
                .put("/register", body.value)
                .then((result) => {
                    onError[0] = false;
                })
                .catch((error) => {
                    onError[0] = true;
                    onError[1] = error;
                });
            return onError;
        };


        return {
            body,
            onSubmit,
        };
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
